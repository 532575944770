import {useEffect, useState} from 'react'
import View from './View'
import Loading from "../../../../Loading";
import {useGetCustomBids, useGetRecommended} from "../../../../../core/hooks";
import {LAMP_FILTER, LAMP_FILTER_MIN, PRODUCT_TYPES} from "../../../../../constants/types";
import {SU} from "../../../../../constants/user/userEnum";

const Lamp = ({
                  description,
                  lamp,
                  setCustomPricing,
                  customPricing,
                  setLamp,
                  fromAll,
                  project,
                  type,
                  onClose,
                  reportId,
                  setRecent,
                  createKey,
                  setCreateKey,
                  area,
                  luminaire,
                  setArea,
                  luminaireId,
                  setType
              }) => {
    const [fetchedDataLamp, setFetchedDataLamp] = useState([])
    const [lampDisabled, setLampDisabled] = useState(false)
    const [selectAll, setSelectAll] = useState(false);
    const [lampAirtable, setLampAirtable] = useState([]);

    const [isOpen, setIsOpen] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const [buyAmerican, setBuyAmerican] = useState(false);
    const [filters, setFilters] = useState({
        wattageMin: '',
        wattageMax: '',
        colorMin: '',
        colorMax: '',
        lumensMin: '',
        lumensMax: '',
        priceMin: '',
        priceMax: '',
        certification: '',
        dimmability: '',
    })
    const [customBids, setCustomBids] = useState([]);
    const [certifications, setCertifications] = useState([])

    const {data, isLoading, refetch} = useGetRecommended(
        {
            condition: {
                table: PRODUCT_TYPES["2"],
                filter: LAMP_FILTER(luminaire),
                filterMin: LAMP_FILTER_MIN(luminaire),
                projectId: project?.id
            }
        }
    )
    const {data: dataCustomBids, isLoading: isLoadingBids, refetch: refetchBids} = useGetCustomBids(
        {
            condition: {
                type: 'report',
                projectId: project?.id,
                productType: 'lamp',
                reportId: reportId,
                userType: SU,
            }
        }
    )

    useEffect(() => {
        if (type === 'lamp') {
            refetch({
                condition: {
                    table: PRODUCT_TYPES["2"],
                    filter: LAMP_FILTER(luminaire),
                    filterMin: LAMP_FILTER_MIN(luminaire),
                    projectId: project?.id
                }
            })
        }
    }, [type])

    const validate = ({fields}) => {
        let bool = true

        if (buyAmerican && bool){
            !!fields['Buy America'] ? bool = fields['Buy America'] === 'Yes' : bool = false
        }
        if (!!filters.wattageMin && !!filters.wattageMax && bool && fields['Wattage']) {
            !!fields['Wattage'] ? bool = parseInt(filters.wattageMin) <= parseInt(fields['Wattage']) && parseInt(filters.wattageMax) >= parseInt(fields['Wattage']) : bool = false
        }
        if (certifications.length > 0 && bool){
            !!fields['Certifications'] ? bool = certifications.includes(fields['Certifications']) : bool = false
        }
        if (!!filters.colorMin && !!filters.colorMax && bool && fields['Color']) {
            !!fields['Color'] ? bool = parseInt(filters.colorMin) <= parseInt(fields['Color']) && parseInt(filters.colorMax) >= parseInt(fields['Color']?.replace(/\D/g, '')) : bool = false
        }
        if (!!filters.priceMin && !!filters.priceMax && bool && fields['Price Per Unit ($)']) {
            !!fields['Price Per Unit ($)'] ? bool = parseInt(filters.priceMin) < parseInt(fields['Price Per Unit ($)']?.replace(/[^0-9.]/g, '')) && parseInt(filters.priceMax) > parseInt(fields['Price Per Unit ($)']?.replace(/[^0-9.]/g, '')) : bool = false
        }
        if (!!filters.lumensMin && !!filters.lumensMax && bool && fields['Lumens']) {
            !!fields['Lumens'] ? bool = parseInt(filters.lumensMin) < parseInt(fields['Lumens']) && parseInt(filters.lumensMax) > parseInt(fields['Lumens']) : bool = false
        }
        if (!!filters.dimmability && bool) {
            !!fields['Dimmable'] ? bool = fields['Dimmable'].includes(filters.dimmability) : bool = false
        }
        if (customPricing.lamp && bool) {
            (!fields.hasOwnProperty('Price Per Unit ($)') || !fields['Price Per Unit ($)'] || Number(fields['Price Per Unit ($)']?.replace(/[^\d.]/g, '')) == 0) ? bool = true : bool = false
        }
        if (!customPricing.lamp && bool){
            if (!fields.hasOwnProperty('Price Per Unit ($)') && !fields.hasOwnProperty('SU PPU ($) Custom')){
                bool = false
            } else if (fields['Price Per Unit ($)'] && !fields['SU PPU ($) Custom'] && Number(fields['Price Per Unit ($)']?.toString()?.replace(/[^\d.]/g, '')) === 0){
                bool = false
            } else {
                bool = true
            }
        }
        return bool
    }

    const setLedsForAll = (areas) => {
        const groupedObjects = new Map();
        areas.forEach((area) => {
            area?.luminaires?.forEach((obj) => {
                const { id, qty, fixtureMountHeight,airtableId, globalQty,globalRecommendedQtyLamp,globalQtyPower,globalQtyControl,powerSupplyRecomendedQty,recomendedFixtureQty,controlRecomendedQty, recomendedLampQty, globalQtyLamp, ...rest } = obj;
                const key = JSON.stringify(rest);

                if (!groupedObjects.has(key)) {
                    groupedObjects.set(key, {
                        ...rest,
                        globalQty: Number(qty),
                        globalQtyLamp: Number(globalQtyLamp),
                        globalQtyPower: Number(globalQtyPower),
                        globalQtyControl: Number(globalQtyControl),
                        globalRecommendedQtyLamp: Number(recomendedLampQty),
                        globalRecomendedFixtureQty: Number(controlRecomendedQty),
                        globalRecommendedQtyControl: Number(recomendedFixtureQty),
                        globalRecommendedQtyPower: Number(powerSupplyRecomendedQty),
                        count: 1
                    });
                } else {
                    const existing = groupedObjects.get(key);
                    groupedObjects.set(key, {
                        ...existing,
                        globalQty: existing.globalQty + Number(qty),
                        globalQtyLamp: existing.globalQtyLamp + Number(globalQtyLamp),
                        globalQtyPower: existing.globalQtyPower + Number(globalQtyPower),
                        globalQtyControl: existing.globalQtyControl + Number(globalQtyControl),
                        globalRecommendedQtyLamp: existing.globalRecommendedQtyLamp + Number(recomendedLampQty),
                        globalRecommendedQtyControl: existing.globalRecommendedQtyControl + Number(controlRecomendedQty),
                        globalRecomendedFixtureQty: existing.globalRecomendedFixtureQty + Number(recomendedFixtureQty),
                        globalRecommendedQtyPower: existing.globalRecommendedQtyPower + Number(powerSupplyRecomendedQty),
                        count: existing.count + 1
                    });
                }
            });
        });
        return Array.from(groupedObjects.values()).filter(
            (group) => group.count > 1
        );
    }

    const create = () => {
        let index = 0;
        let ides = setLedsForAll(area);
        let newArray = [];

        if (fromAll) {
            newArray = area?.map((e, i) => {
                return {
                    ...e,
                    luminaires: e?.luminaires.map((led) => {
                        let newObj = { ...led };

                        if (ides.length > 0) {
                            for (let j = 0; j < ides.length; j++) {
                                let { id, qty,powerSupplyRecomendedQty,airtableId, controlRecomendedQty,recomendedLampQty,fixtureMountHeight,recomendedFixtureQty,globalRecommendedQtyPower,globalQtyPower,globalRecommendedQtyLamp,globalRecommendedQtyControl,globalQtyControl, globalQty,globalRecomendedFixtureQty, globalQtyLamp, ...rest } = ides[j];
                                let { id: targetId, qty: targetQty,airtableId:airtableId1,recomendedLampQty:recomendedLampQty1,controlRecomendedQty:controlRecomendedQty1,powerSupplyRecomendedQty:powerSupplyRecomendedQty1,recomendedFixtureQty:recomendedFixtureQty1,globalQtyPower:globalQtyPower1,globalRecommendedQtyPower:globalRecommendedQtyPower1,globalRecommendedQtyLamp:globalRecommendedQtyLamp1,globalRecommendedQtyControl:globalRecommendedQtyControl1,globalQtyControl:globalQtyControl1, fixtureMountHeight: targetHeight,globalRecomendedFixtureQty: targetglobalRecomendedFixtureQty, globalQty: targetGlobalQty, globalQtyLamp: targetGlobalQtyLamp, ...obj } = newObj;
                                let { globalQty: glob,recomendedLampQty:recomendedLampQty2,controlRecomendedQty:controlRecomendedQty2,powerSupplyRecomendedQty:powerSupplyRecomendedQty2,recomendedFixtureQty:recomendedFixtureQty2,globalQtyPower:globalQtyPower2,globalRecommendedQtyPower:globalRecommendedQtyPower2,globalRecommendedQtyLamp:globalRecommendedQtyLamp2,globalRecommendedQtyControl:globalRecommendedQtyControl2,globalQtyControl:globalQtyControl2, globalQtyLamp: globLamp,globalRecomendedFixtureQty: globFixtureRecom, ...lumin } = luminaire;

                                if (JSON.stringify(rest) === JSON.stringify(lumin)) {
                                    if (ides[j].id === newObj.id) {
                                        newObj = {
                                            ...newObj,
                                            ...lamp,
                                            recomendedLampQty : led.recomendedLampQty ?? 1
                                        };
                                        index = i;
                                    }
                                    if (!globalRecommendedQtyLamp){
                                        newObj.recomendedLampQty = led.lampPerFixture ? led.lampPerFixture : 1
                                    }
                                } else if (JSON.stringify(lumin) === JSON.stringify(obj)) {
                                    newObj = {
                                        ...newObj,
                                        ...lamp,
                                        recomendedLampQty : led.recomendedLampQty ?? 1
                                    };
                                    if (!globalRecommendedQtyLamp2){
                                        newObj.recomendedLampQty = led.lampPerFixture ? led.lampPerFixture : 1
                                    }
                                    index = i;
                                }
                            }
                        } else {
                            let { id, qty, fixtureMountHeight,airtableId,powerSupplyRecomendedQty, controlRecomendedQty,recomendedLampQty, recomendedFixtureQty,globalRecommendedQtyControl,globalQtyPower,globalRecommendedQtyPower,globalQtyControl,globalRecommendedQtyLamp, globalQty,globalRecomendedFixtureQty, globalQtyLamp, ...rest } = newObj;
                            let { globalQty: glob,airtableId:airtableId1,powerSupplyRecomendedQty:powerSupplyRecomendedQty1, controlRecomendedQty:controlRecomendedQty1,recomendedLampQty:recomendedLampQty1, globalQtyLamp: globLamp,globalQtyPower:globalQtyPower1,globalRecommendedQtyControl:globalRecommendedQtyControl1,globalRecommendedQtyPower:globalRecommendedQtyPower1,globalRecommendedQtyLamp:globalRecommendedQtyLamp1,recomendedFixtureQty:recomendedFixtureQty1,globalQtyControl:globalQtyControl1, globalRecomendedFixtureQty: globFixtureRecom, ...lumin } = luminaire;

                            if (JSON.stringify(rest) === JSON.stringify(lumin)) {
                                newObj = {
                                    ...newObj,
                                    ...lamp,
                                };
                                index = i;
                            }
                        }

                        return newObj;
                    })
                };
            });
        } else {
            newArray = area?.map((e, i) => {
                return {
                    ...e,
                    luminaires: e?.luminaires.map((luminaire) => {
                        let newObj = { ...luminaire };

                        if (newObj.id === luminaireId || ides.includes(newObj.id)) {
                            newObj = {
                                ...newObj,
                                ...lamp,
                            };
                            index = i;
                        }

                        return newObj;
                    })
                };
            });
        }
        setArea(newArray);
        // setRecent([...newArray])
    }


    useEffect(() => {
        if (createKey === true) {
            create()
            setCreateKey(false)
            onClose()
        }
    },[createKey])

    useEffect(() => {
        if (!data) return
        setFetchedDataLamp(data?.data?.data)
        setLamp({
            ...lamp,
            recomendedLampQty: luminaire.recomendedLampQty ? luminaire.recomendedLampQty  : luminaire.globalRecommendedQtyLamp ? luminaire.globalRecommendedQtyLamp
                : luminaire.globalQtyLamp ? luminaire.globalQtyLamp : (luminaire.lampPerFixture ? luminaire.lampPerFixture : '')
        })
        if (luminaire?.fixtureRecomendedAirtable && luminaire?.fixtureRecomendedAirtable?.length > 0) {
            let recom = JSON.parse(luminaire?.fixtureRecomendedAirtable)
            setLampDisabled(!!recom['Wattage'])
        } else {
            setLampDisabled(false)
        }
        setSelectedItems(lamp?.lampRecomendedCustomPriceIdes?.length > 0 ? lamp.lampRecomendedCustomPriceIdes : luminaire?.lampRecomendedCustomPriceIdes?.length > 0 ? luminaire.lampRecomendedCustomPriceIdes : [])
        setLampAirtable(lamp?.lampRecomendedAirtableArray?.length > 0 ? lamp.lampRecomendedAirtableArray : luminaire?.lampRecomendedAirtableArray?.length > 0 ? luminaire.lampRecomendedAirtableArray : [])
        if (dataCustomBids){
            setCustomBids(dataCustomBids.data.data)
        }
        data.data.data.forEach(item1 => {
            item1.fields['Bid Status'] = 'review'
            if (dataCustomBids){
                dataCustomBids.data.data.forEach(item2 => {
                    if (item1.id === item2.fields['Product ID']) {
                        if (item2.fields['SU PPU ($)']){
                            item1.fields['SU PPU ($) Custom'] = item2.fields['SU PPU ($)'];
                            item1.fields['SU PPU ($) Custom FE'] = item2.fields['FE PPU ($)'];
                            item1.fields['Bid Status'] = 'accepted'
                        } else {
                            item1.fields['Bid Status'] = 'pending'
                        }
                    }
                });
            }
        });
    }, [data, dataCustomBids])

    const handleSelect = (item) => {
        setSelectedItems(prevSelectedItems => {
            if (prevSelectedItems.includes(item)) {
                return prevSelectedItems.filter(i => i !== item);
            } else {
                return [...prevSelectedItems, item];
            }
        });
    };

    const handleSelectAll = () => {
        if (selectAll) {
            setSelectedItems([]);
        } else {
            setSelectedItems(fetchedDataLamp?.filter((item) => validate(item) === true).map(item => item.id));
        }
        setSelectAll(!selectAll);
    };

    useEffect(() => {
        if (selectedItems.length > 0) {
            if (customPricing.lamp) {
                setLamp({
                    ...lamp,
                    lampRecomendedCustomPriceIdes: selectedItems,
                    lampRecomendedAirtable: lampAirtable?.length > 0 && JSON.stringify(lampAirtable[0].fields),
                })
            } else {
                setLamp({
                    ...lamp,
                    lampRecomendedCustomPriceIdes: [],
                })
            }
        }  else {
            setLamp({
                ...lamp,
                lampRecomendedCustomPriceIdes: [],
            })
        }
    }, [selectedItems])

    useEffect(() => {
        if (lampAirtable.length > 0){
            setLamp({
                ...lamp,
                lampRecomendedAirtable: JSON.stringify(lampAirtable[0].fields),
                lampRecomendedAirtableArray: lampAirtable,
                lampRecomendedDescription: lampAirtable[0].fields['FE Product Description'],
                lampRecomendedPrice: lampAirtable[0].fields['Price Per Unit ($)'],
                lampRecomendedWattage: lampAirtable[0].fields['Wattage']
            })
        } else {
            setLamp({
                ...lamp,
                lampRecomendedAirtable: [],
                lampRecomendedAirtableArray: [],
                lampRecomendedDescription: '',
                lampRecomendedPrice: '',
                lampRecomendedWattage: ''
            })
        }
    },[lampAirtable])

    if (isLoading) return <Loading height='100%'/>

    return (
        <View
            setBuyAmerican={setBuyAmerican}
            buyAmerican={buyAmerican}
            setIsOpen={setIsOpen}
            certifications={certifications}
            isOpen={isOpen}
            projectId={project?.id}
            refetch={refetch}
            selectedItems={selectedItems}
            customPricing={customPricing}
            setLampAirtable={setLampAirtable}
            handleSelectAll={handleSelectAll}
            selectAll={selectAll}
            handleSelect={handleSelect}
            fromAll={fromAll}
            lampAirtable={lampAirtable}
            setCustomPricing={setCustomPricing}
            lampDisabled={lampDisabled}
            filters={filters}
            setFilters={setFilters}
            validate={validate}
            luminaire={luminaire}
            setSelectedItems={setSelectedItems}
            setType={setType}
            fetchedDataLamp={fetchedDataLamp}
            lamp={lamp}
            description={description}
            setLamp={setLamp}
            create={create}/>
    )
}

export default Lamp
